// type Props = {};

import { useAppSelector } from "lib/store/hooks";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { useState } from "react";
import Tabs from "react-components/dist/components/Tabs";
import { Link } from "react-router-dom";
import modal from "react-components/dist/components/Modal";

import { formatDistanceToNow } from "date-fns";
import { id as ID } from "date-fns/locale";

import useNotification from "./useNotification";

function ModalNotification() {
  const [currentTab, setCurrentTab] = useState("pelayanan");
  const { selected } = useAppSelector((state) => state.clinics);
  const notifications = useAppSelector((state) => state.notifications);

  const { updateNotificationRead } = useNotification();

  return (
    <div className="relative">
      <span className="absolute -left-[7px] top-5 h-4 w-4 -rotate-45 transform rounded border-l border-t border-gray-200 bg-white"></span>
      <div className="flex flex-col">
        <Link
          to={`/clinics/${selected?._id}/notifications`}
          onClick={modal.hide}
        >
          <h6 className="flex gap-x-2 px-4 pt-4 font-medium">
            Notifikasi
            <ArrowSquareOut size={24} />
          </h6>
        </Link>

        <div className="pr-4">
          <Tabs
            list={[
              { key: "pelayanan", label: "Pelayanan" },
              // { key: "system", label: "Sistem" },
            ]}
            onChange={setCurrentTab}
            current={currentTab}
            classNameWrapper="px-2"
          />
        </div>
        <hr className="bg-gray-200" />

        {currentTab === "pelayanan" && (
          <div style={{ maxHeight: 265 }} className="overflow-y-scroll">
            {/* {notifications?.unread === 0 && (
              <div className="flex items-center justify-center">
                Tidak ada notifikasi
              </div>
            )} */}
            <ul className="flex flex-col gap-y-1 p-2">
              {(notifications?.messages || []).map((item) => {
                return (
                  <li
                    className="group relative flex cursor-pointer flex-col rounded px-4 py-2 hover:bg-slate-50"
                    key={item._id}
                  >
                    <span className="flex items-center justify-between">
                      <h6 className="text-sm">{item.title}</h6>
                      {!item.isRead && (
                        <span className="ml-auto mr-1 -translate-y-[2px] transform text-yellow-700">
                          &bull;
                        </span>
                      )}
                      <span className="text-xs">
                        {formatDistanceToNow(new Date(item.createdAt), {
                          locale: ID,
                        })}
                      </span>
                    </span>
                    <p className="text-xs">{item.message}</p>
                    {!item.isRead && (
                      <span
                        className="absolute bottom-0 right-0 rounded bg-slate-50 p-2 text-xs text-blue-500 opacity-0 hover:underline group-hover:opacity-100"
                        onClick={() => updateNotificationRead(item._id)}
                      >
                        Tandai sudah dibaca
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModalNotification;

export function createResource(promise: Promise<any>): any {
  let status = "pending";

  let result = promise
    // .then((response) => response.json())
    .then(
      (resolved) => {
        // setTimeout(() => {
        status = "success";
        result = resolved;
        // }, 100000);
      },
      (rejected) => {
        status = "error";
        result = rejected;
      }
    );

  return {
    read() {
      // console.log(status);
      if (status === "pending") throw result;
      if (status === "error") throw result;
      if (status === "success") return result;
      throw new Error("This should be impossible");
    },
  };
}

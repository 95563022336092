import { createSlice } from "@reduxjs/toolkit";

type TMessagesNotifications = {
  _id: string;
  key: string;
  title: string;
  message: string;
  type: string;
  referenceType: string;
  referenceId: string;
  isRead: boolean;
  metaData: {
    [key: string]: unknown;
  };
  createdAt: string;
};

export interface IComponentsState {
  count: number;
  unread: number;
  isRinging: boolean;
  messages: TMessagesNotifications[];
}

const initialState: IComponentsState = {
  count: 0,
  unread: 0,
  isRinging: false,
  messages: [],
};

export const componentsSlicer = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    init: (state: IComponentsState) => {
      state = initialState;
    },
    update: (
      state: IComponentsState,
      action: {
        payload: {
          count: number;
          data: TMessagesNotifications[];
        };
      }
    ) => {
      state.count = action.payload.count;
      state.unread = action.payload.data.filter((item) => !item.isRead).length;
      state.messages = action.payload.data;
    },
    ring: (
      state: IComponentsState,
      action: {
        payload: boolean;
      }
    ) => {
      state.isRinging = action.payload;
    },
    read: (
      state: IComponentsState,
      action: {
        payload: string;
      }
    ) => {
      const index = state.messages.findIndex(
        (item) => item._id === action.payload
      );
      state.messages[index].isRead = true;
      state.unread = state.messages.filter((item) => !item.isRead).length;
    },
  },
});

export const { init, update, ring, read } = componentsSlicer.actions;

export default componentsSlicer.reducer;

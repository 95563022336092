import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import Button from "react-components/dist/components/Button";
import { toggleSidebar } from "lib/store/slices/components";
import { List } from "@phosphor-icons/react";

// import useImageOrientation from "lib/helpers/useImageOrientation";

interface Props {}

const Header = (props: Props) => {
  const clinics = useAppSelector((state) => state.clinics);
  const dispatch = useAppDispatch();

  // const [imageOrientation, orientationSize] = useImageOrientation({
  //   image: clinics.selected?.logo || "",
  // });

  return (
    <header className="sticky top-0 z-40 h-14 bg-white shadow-sm dark:shadow-gray-800 dark:bg-gray-900 dark:text-gray-200 lg:hidden">
      <div className="flex h-full items-center">
        <Button className="mx-2 rounded-full px-2" state="custom" ripple>
          <button onClick={() => dispatch(toggleSidebar(true))}>
            <List size={24} />
          </button>
        </Button>
        {clinics.selected && (
          <div className="flex gap-x-3 items-center">
            {/* {imageOrientation !== "landscape2x" && (
              <img
                className={[
                  "flex-none object-contain",
                  orientationSize[imageOrientation],
                ].join(" ")}
                src={clinics.selected?.logo}
                alt={clinics.selected?.name}
              />
            )} */}

            <span className="">/ {clinics.selected?.name}</span>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

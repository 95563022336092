import { useAppDispatch, useAppSelector } from "lib/store/hooks";

import { useCallback } from "react";
import { toggleSidebar } from "lib/store/slices/components";
import { selectClinic, setAvailableClinic } from "lib/store/slices/clinics";
import { resetCalendarState } from "lib/store/slices/calendars";
import { resetServiceState } from "lib/store/slices/services";
import { logout } from "lib/store/slices/users";

import modal from "react-components/dist/components/Modal";

function useControlClinic() {
  const dispatch = useAppDispatch();

  const clinics = useAppSelector((state) => state.clinics);

  const fnLogout = useCallback(async () => {
    if (clinics.selected) {
      dispatch(toggleSidebar(false));
      dispatch(selectClinic(null));
      dispatch(setAvailableClinic([]));
      dispatch(resetCalendarState());
      dispatch(resetServiceState());
      // dispatch(resetAllForms());
      dispatch(logout());
    }
    modal.hide();
  }, [dispatch, clinics]);

  return fnLogout;
}

export default useControlClinic;

import { createSlice } from "@reduxjs/toolkit";

export type TTutorials = {
  youtube: {
    embedURI: string;
    title: string;
  } | null;
};

const initialState: TTutorials = {
  youtube: null,
};

export const slice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    updateYoutube: (
      state,
      action: {
        payload: {
          embedURI: string;
          title: string;
        };
      }
    ) => {
      state.youtube = action.payload;
    },
    resetYoutube: (state) => {
      state.youtube = null;
    },
  },
});

export const { updateYoutube, resetYoutube } = slice.actions;

export default slice.reducer;

import ProtectedClinicRoute from "components/atoms/ProtectedClinicRoute";
import Header from "components/organisms/Header";
import Sidebar from "components/organisms/Sidebar";

// type Props = {};

function AuthorizedOnly() {
  return (
    <ProtectedClinicRoute>
      <>
        <Header />
        <div className="flex">
          <Sidebar />
          <main className="flex min-h-screen w-full flex-none items-center justify-center px-4 md:flex-auto">
            <div className="w-full md:w-[400px]">
              <h1 className="text-lg text-gray-600 dark:text-gray-400">
                Error 403
              </h1>
              <p className="font-medium text-gray-900 dark:text-gray-200">
                Anda tidak memiliki hak akses pada halaman ini
              </p>
            </div>
          </main>
        </div>
      </>
    </ProtectedClinicRoute>
  );
}

export default AuthorizedOnly;

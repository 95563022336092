import { UserFocus } from "@phosphor-icons/react";
import { useAppSelector } from "lib/store/hooks";
import Button from "react-components/dist/components/Button";

import { ReactComponent as Satusehat } from "assets/images/satusehat.svg";
import APISatusehat from "lib/constants/api/satusehat";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function KnownYourCustomer() {
  const { selected } = useAppSelector((state) => state.clinics);
  const users = useAppSelector((state) => state.users);

  const navigate = useNavigate();

  const [isLoading, isLoadingSet] = useState(false);

  async function createLinkKYC() {
    try {
      if (!users.profile?.fullName || !users.profile?.idCard)
        throw new Error("Lengkapi Nama serta NIK Anda dahulu");

      isLoadingSet(true);
      const response = await APISatusehat.createKYC({
        agent_name: users.profile?.fullName,
        agent_nik: users.profile?.idCard,
      });

      isLoadingSet(false);
      window.open(
        response.data.data.url,
        "satusehat-ekyc",
        "menubar=1,resizable=1,width=550,height=700"
      );
      toast.info(
        "Silahkan masukkan kode yang terdapat pada aplikasi satusehat milih pasien ke jendela verifikator"
      );
    } catch (error: any) {
      isLoadingSet(false);
      navigate("/ubah-profil");
      toast.warning(
        error?.isAxiosError ? error?.response?.data?.message : error?.message
      );
    }
  }

  if (!selected?.metaDataSatuSehat?.isActive) return null;

  return (
    <div className="group relative mt-1">
      <Button
        isLoading={isLoading}
        ripple
        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900 relative"
        state="custom"
      >
        <button onClick={createLinkKYC}>
          {/* <button onClick={getKioskPage} onContextMenu={onContextMenuKiosk}> */}
          <UserFocus size={26} />
        </button>
      </Button>
      <span
        className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border
        border-white bg-white"
      >
        <Satusehat className="logo-satusehat h-3" />
      </span>
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        Verifikasi profil satusehat milik pasien
      </span>
    </div>
  );
}

export default KnownYourCustomer;

import { useAppSelector } from "lib/store/hooks";
import { Link } from "react-router-dom";

function ContextMenuKiosk({ onClose }: any) {
  const { selected } = useAppSelector((state) => state.clinics);
  return (
    <div className="flex flex-col p-1">
      <ul className="flex flex-col gap-y-1">
        <li
          className={[
            "flex gap-x-3 items-center px-3 py-1 hover:bg-gray-100 hover:dark:bg-gray-800 dark:text-gray-200 cursor-pointer",
          ].join(" ")}
        >
          <Link
            to={`/clinics/${selected?._id}/settings/kiosk`}
            onClick={onClose}
          >
            Pengaturan KIOSK
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default ContextMenuKiosk;

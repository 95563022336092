import { useLayoutEffect, useState } from "react";

type Props = {
  image: string;
};

type TImageOrientation =
  | "landscape"
  | "landscape2x"
  | "square"
  | "portrait"
  | "default";

// type TOrientation = { [key: string]: string };

function useImageOrientation({ image }: Props) {
  const [imageOrientation, imageOrientationSet] =
    useState<TImageOrientation>("default");

  useLayoutEffect(() => {
    setTimeout(() => {
      if (image) {
        const myImage = new Image();
        myImage.src = image;

        if (myImage.width === myImage.height) {
          // console.log("1", myImage.width, myImage.height);
          imageOrientationSet("square");
        } else if (myImage.width - myImage.height < 74) {
          // console.log("2", myImage.width, myImage.height);
          imageOrientationSet("square");
        } else if (myImage.width - myImage.height >= 75) {
          // console.log("3", myImage.width, myImage.height);
          imageOrientationSet("landscape");
        } else if (myImage.width - myImage.height > 125) {
          // console.log("4", myImage.width, myImage.height);
          imageOrientationSet("landscape2x");
        } else if (myImage.width < myImage.height) {
          // console.log("5", myImage.width, myImage.height);
          imageOrientationSet("portrait");
        }
      }
    }, 200);
  }, [imageOrientationSet, image]);

  const orientationSize: any = {
    default: "opacity-0",
    square: "h-10 aspect-square overflow-hidden rounded-md",
    portrait: "h-10",
    landscape: "h-full",
    landscape2x: "h-full",
  };

  return [imageOrientation, orientationSize];
}

export default useImageOrientation;

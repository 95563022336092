import auth from "lib/constants/api/auth";

import axios, { setAuthorizationHeader } from "./axios";
import store from "lib/store";

import { toggleSidebar } from "lib/store/slices/components";
import { refresh } from "lib/store/slices/users";
import { selectClinic, setAvailableClinic } from "lib/store/slices/clinics";
import { resetCalendarState } from "lib/store/slices/calendars";
import { resetServiceState } from "lib/store/slices/services";
import { logout } from "lib/store/slices/users";

const errorHandler = async (error: any) => {
  if (error) {
    if (
      error?.response?.data?.errors?.global === "Token Expired" ||
      error?.response?.data?.message === "TokenExpiredError"
    ) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = store.getState().users.tokenRefresh;

        if (refreshToken) {
          try {
            const refreshed = await auth.refresh({ refreshToken });

            if (refreshed) {
              const { user } = refreshed.data;

              store.dispatch(
                refresh({ token: user.token, tokenRefresh: user.tokenRefresh })
              );

              setAuthorizationHeader(user.token);

              originalRequest.headers.authorization = `Bearer ${user.token}`;

              return axios(originalRequest);
            }
          } catch (error) {
            store.dispatch(toggleSidebar(false));
            store.dispatch(selectClinic(null));
            store.dispatch(setAvailableClinic([]));
            store.dispatch(resetCalendarState());
            store.dispatch(resetServiceState());
            store.dispatch(logout());

            window.location.href = "/login?reason=session-expired";
          }
        }
      }
    }
  }
  return Promise.reject(error);
};

export default errorHandler;

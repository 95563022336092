import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import errorHandler from "./errorHandler";

const instance = axios.create({
  timeout: 1000 * 60 * 5,
});

export const setAuthorizationHeader = (token: string | null = null) => {
  if (token) {
    instance.defaults.headers.common.authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.authorization;
  }
};

instance.interceptors.response.use((response) => response, errorHandler);

// export const CancelToken = axios.CancelToken;
// export const _axios_ = axios;
function newAxios(params: any) {
  return instance({
    ...params,
    headers: {
      ...(params?.headers || {}),
      "X-Request-ID": uuidv4(),
    },
  });
}

export default newAxios;

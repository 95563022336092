import { useCallback, useRef } from "react";

import APINotifications from "lib/constants/api/notifications";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { update, read, ring } from "lib/store/slices/notifications";
import useLocalStorage from "lib/helpers/useLocalstorage";

export default function useNotification() {
  const notifications = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();

  const refInterfalNotification = useRef<
    ReturnType<typeof setInterval> | number
  >(0);

  const [muteNotifications] = useLocalStorage<boolean>(
    "muteNotifications",
    false
  );

  const refTimeoutRinging = useRef<ReturnType<typeof setTimeout> | number>(0);

  const actionPermission = useCallback((command: () => void) => {
    try {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // const notification = new Notification("Hi there!")
        command();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // const notification = new Notification("Hi there!");
            command();
          }
        });
      }
    } catch (error) {
      console.log("Notification not allowed by user");
    }
  }, []);

  const shortPoolNotifications = useCallback(() => {
    const SECONDS = 30;
    try {
      clearInterval(refInterfalNotification.current);
      refInterfalNotification.current = setInterval(async () => {
        const getNotifications = await APINotifications.getNotifications({
          limit: 20,
          page: 1,
        });
        dispatch(update(getNotifications.data));

        const unread = getNotifications.data.data.filter(
          (item: any) => !item.isRead
        ).length;
        // console.log(
        //   notifications.unread !== unread,
        //   notifications.unread,
        //   unread,
        //   unread > 0
        // );
        if (notifications.unread !== unread && unread > 0) {
          actionPermission(() => {
            new Notification("Mejadokter ada pemberitahuan baru");
          });

          dispatch(ring(true));
          clearTimeout(refTimeoutRinging.current);
          refTimeoutRinging.current = setTimeout(() => {
            dispatch(ring(false));
          }, 1000);

          if (!muteNotifications) {
            const notificationSound = new Audio(
              "/assets/mjd-notification-sound.wav"
            );
            notificationSound.play();
          }
        }
      }, 1000 * SECONDS);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, notifications.unread, actionPermission, muteNotifications]);

  async function updateNotificationRead(id: string) {
    try {
      await APINotifications.readNotification(id);
      dispatch(read(id));
    } catch (error) {
      console.log(error);
    }
  }

  return {
    refInterfalNotification,
    shortPoolNotifications,
    updateNotificationRead,
    actionPermission,
  };
}

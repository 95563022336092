import Header from "components/organisms/Header";
import Sidebar from "components/organisms/Sidebar";

// type Props = {};

function NotFound() {
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebar />
        <main className="flex min-h-screen w-screen flex-none items-center justify-center px-4 md:flex-auto">
          <div className="w-full md:w-[400px]">
            <h1 className="text-lg">Error 404</h1>
            <p className="font-medium">Halaman tidak ditemukan</p>
          </div>
        </main>
      </div>
    </>
  );
}

export default NotFound;

import { useAppSelector } from "lib/store/hooks";
import { ReactComponent as Logo } from "assets/images/logo-main.svg";

import { useNavigate } from "react-router-dom";
import Spinner from "react-components/dist/components/Spinner";
import { useEffect } from "react";

const ProtectedClinicRoute = ({ children }: { children: JSX.Element }) => {
  const { token } = useAppSelector((state) => state.users);
  const components = useAppSelector((state) => state.components);
  const clinics = useAppSelector((state) => state.clinics);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!clinics.selected) {
      navigate("/clinics", { replace: true });
    }
  }, [clinics.selected, navigate]);

  if (!components.app.isReady)
    return (
      <main className="flex h-screen w-screen items-center justify-center">
        <div className="mx-12 text-center">
          <Logo width={220} className="logo-yellow mx-auto" />
          <span className="mt-4 flex flex-col-reverse items-center text-sm md:flex-row">
            <Spinner size="sm" />
            <span className="mb-4 ml-3 md:mb-0">
              Mempersiapkan aplikasi penunjang faskes Anda
            </span>
          </span>
        </div>
      </main>
    );

  return children;
};

export default ProtectedClinicRoute;

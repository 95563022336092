import { Bell, BellRinging, BellSlash } from "@phosphor-icons/react";

import Button from "react-components/dist/components/Button";
import { useLocation } from "react-router-dom";
import modal from "react-components/dist/components/Modal";
import ModalNotification from "./ModalNotification";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { useCallback, useEffect } from "react";

import { init } from "lib/store/slices/notifications";

import useNotification from "./useNotification";
import ContextMenuNotificationButton from "./ContextMenuNotificationButton";
import useLocalStorage from "lib/helpers/useLocalstorage";

function Notifikasi() {
  const location = useLocation();
  const notifications = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();

  const [muteNotifications] = useLocalStorage<boolean>(
    "muteNotifications",
    false
  );

  const fnModalNotification = useCallback(() => {
    if (!location.pathname.includes("/notifications"))
      modal.show(<ModalNotification />, {
        close: {
          outsideClick: true,
          closeOnEscape: true,
          display: false,
        },
        container: {
          className: "flex justify-start min-h-screen",
        },
        overlay: {
          className:
            "transition-all duration-300 fixed inset-0 z-50 overflow-x-hidden",
          animatedClassNames: {
            appear: "",
            appearActive: "",
            appearDone: "",
            enter: "",
            enterActive: "",
            enterDone: "",
            exit: "",
            exitActive: "",
            exitDone: "",
          },
        },
        wrapper: {
          className:
            "absolute max-h-[360px] max-w-full w-[420px] top-[173px] rounded border border-gray-200 relative bg-white transition-all duration-300 shadow-2xl",
          timeout: 300,
          animatedClassNames: {
            appear: "-left-full opacity-0",
            appearActive: "-left-full opacity-0",
            appearDone: "opacity-100 left-[60px]",
            enter: "-left-full opacity-100",
            enterActive: "-left-full opacity-100",
            enterDone: "left-[60px] opacity-100",
            exit: "left-[60px]",
            exitActive: "-left-full opacity-0",
            exitDone: "-left-full opacity-0",
          },
        },
      });
  }, [location.pathname]);

  const { shortPoolNotifications, refInterfalNotification } = useNotification();

  function onContextMenuNotificationButton(e: any) {
    e.preventDefault();

    modal.show(<ContextMenuNotificationButton />, {
      close: {
        outsideClick: true,
        closeOnEscape: true,
        display: false,
      },
      container: {
        className: "",
      },
      overlay: {
        timeout: 0,
        // className: "absolute top-0 left-0",
        // overrideClassName: "bg-black/0",
        animatedClassNames: {
          appear: "opacity-0 bg-black/0",
          appearActive: "opacity-0 bg-black/0",
          appearDone: "opacity-100 bg-black/0",
          enter: "opacity-0 bg-black/0",
          enterActive: "opacity-0 bg-black/0",
          enterDone: "opacity-100 bg-black/0",
          exit: "",
          exitActive: "opacity-0 bg-black/0",
          exitDone: "opacity-0 bg-black/0",
        },
      },
      wrapper: {
        className:
          "bg-white dark:bg-gray-900 w-[260px] relative bg-white shadow-lg rounded",
        timeout: 0,
        style: {
          left: e.clientX,
          top: e.clientY,
        },
      },
    });
  }

  useEffect(() => {
    shortPoolNotifications();
    dispatch(init());

    const refClear = refInterfalNotification.current;

    return () => {
      clearInterval(refClear);
    };
  }, [dispatch, shortPoolNotifications, refInterfalNotification]);

  return (
    <div className="group relative">
      <Button
        ripple
        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
        state="custom"
      >
        <button
          type="button"
          onClick={fnModalNotification}
          onContextMenu={onContextMenuNotificationButton}
        >
          <span className={notifications?.isRinging ? "ringing" : ""}>
            {notifications?.isRinging ? (
              <BellRinging size={22} />
            ) : muteNotifications ? (
              <BellSlash size={22} />
            ) : (
              <Bell size={22} />
            )}
          </span>
        </button>
      </Button>
      {!location.pathname.includes("/notifications") &&
        notifications?.unread > 0 && (
          <span
            className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border
        border-white bg-yellow-700 text-xxs font-bold text-blue-900"
          >
            {notifications?.unread || 0}
          </span>
        )}
      {location.pathname.includes("/notifications") && (
        <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
      )}
      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
        Notifikasi
      </span>
    </div>
  );
}

export default Notifikasi;

import { BellRinging, BellSlash } from "@phosphor-icons/react";
import useLocalStorage from "lib/helpers/useLocalstorage";

// type Props = {}

function ContextMenuNotificationButton() {
  const [muteNotifications, muteNotificationsSet] = useLocalStorage<boolean>(
    "muteNotifications",
    false
  );

  return (
    <div className="flex flex-col p-1">
      <ul className="flex flex-col gap-y-1">
        <li
          className={[
            "flex gap-x-3 items-center px-3 py-1 whitespace-nowrap",
            !muteNotifications
              ? "bg-gray-200 dark:bg-gray-700 dark:text-gray-200 cursor-default select-none"
              : "cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800 dark:text-gray-200",
          ].join(" ")}
          onClick={() => {
            muteNotificationsSet(false);
          }}
        >
          <BellRinging size={22} />
          Notifikasi dengan sound
        </li>
        <li
          className={[
            "flex gap-x-3 items-center  px-3 py-1 whitespace-nowrap",
            !muteNotifications
              ? "hover:bg-gray-100 hover:dark:bg-gray-800 dark:text-gray-200 cursor-pointer"
              : "bg-gray-200 dark:bg-gray-700 dark:text-gray-200 cursor-default select-none",
          ].join(" ")}
          onClick={() => {
            muteNotificationsSet(true);
          }}
        >
          <BellSlash size={22} />
          Notifikasi muted
        </li>
      </ul>
    </div>
  );
}

export default ContextMenuNotificationButton;

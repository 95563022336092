import { createSlice } from "@reduxjs/toolkit";

export interface IServicesState {
  states: {
    "rawat-jalan": {
      all: number;
      "bpjs-antrol": number;
      lists: string[];
      [key: string]: any;
    };
    "rawat-inap": number;
    igd: number;
    ugd: number;
    radiologi: number;
    oncall: number;
    laboratorium: number;
    "instalasi-farmasi": number;
    waiting: number;
  };
  form: any;
}

const initialState: IServicesState = {
  states: {
    "rawat-jalan": {
      all: 0,
      "bpjs-antrol": 0,
      lists: [],
    },
    "rawat-inap": 0,
    igd: 0,
    ugd: 0,
    oncall: 0,
    radiologi: 0,
    laboratorium: 0,
    "instalasi-farmasi": 0,
    waiting: 0,
  },
  form: null,
};

export const clinicsSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    updateServiceState: (state, action) => {
      state.states = action.payload;
    },
    updateForm: (state, action) => {
      state.form = action.payload;
    },
    resetServiceState: (state) => {
      state.states = initialState.states;
    },
  },
});

export const { updateServiceState, resetServiceState, updateForm } =
  clinicsSlice.actions;

export default clinicsSlice.reducer;

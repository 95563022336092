import Button from "react-components/dist/components/Button";

type Props = {
  error: any;
  resetErrorBoundary: any;
};

function ErrorFallback({ error, resetErrorBoundary }: Props) {
  if (error?.response?.status === 404) {
    return (
      <div
        role="alert"
        className="border border-yellow-500 bg-yellow-100 px-8 py-4 text-center"
      >
        {error?.isAxiosError ? error?.response?.data?.message : error?.message}
        <Button state="secondary" size="sm" className="px-4">
          <button onClick={resetErrorBoundary}>Reset</button>
        </Button>
      </div>
    );
  }

  return (
    <div role="alert" className="border border-red-500 bg-red-100 px-8 py-4">
      There was an error:{" "}
      <pre style={{ whiteSpace: "normal" }}>
        {error?.isAxiosError ? error?.response?.data?.message : error?.message}
      </pre>
      <Button state="secondary" size="sm" className="px-4">
        <button onClick={resetErrorBoundary}>Reset</button>
      </Button>
    </div>
  );
}

export default ErrorFallback;

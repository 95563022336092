import useImageOrientation from "lib/helpers/useImageOrientation";
import { useAppSelector } from "lib/store/hooks";

// import Balance from "./Balance";
import { memo } from "react";

const Heading = () => {
  const clinics = useAppSelector((state) => state.clinics);

  const [imageOrientation, orientationSize] = useImageOrientation({
    image: clinics.selected?.logo || "",
  });

  return (
    <div className="mt-3 gap-x-3 flex h-12 w-full items-center justify-start px-4">
      <img
        className={[
          "flex-none object-contain",
          orientationSize[imageOrientation],
        ].join(" ")}
        src={clinics.selected?.logo}
        alt={clinics.selected?.name}
      />

      {imageOrientation !== "landscape2x" && (
        <h1
          title={clinics.selected?.name}
          className="truncate text-sm font-medium text-gray-700 dark:text-gray-50"
        >
          {clinics.selected?.name}
        </h1>
      )}

      {/* <Balance /> */}
    </div>
  );
};

export default memo(Heading);

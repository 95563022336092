import axios from "lib/fetch/axios";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";
// export type TFilterPendaftaran = {
//   tglDaftar: Date;
//   page: number;
//   limit: number;
// };

// export type TDetailKunjungan = {
//   noUrut: string;
//   tglDaftar: string;
//   noKartu: string;
// };

export type TTypePatientSearch =
  | "birthMother"
  | "nik"
  | "name-birthdate-gender";

function getPatientDetailsByNIK(code: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/patients/nik/${code}`,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
  });
}

function getPatientDetailsByNIKMother(code: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/patients/nik-ibu/${code}`,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
  });
}

export type TPatientIdentity = {
  name?: string;
  birthdate?: string;
  gender?: "male" | "female";
};
function getPatientDetailsByIdentity(params: TPatientIdentity) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/patients/name-birthdate-gender`,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
    params,
  });
}

function getPractionerByNIK(code: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/practitioners/nik/${code}`,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
  });
}

function createLocation(data: any) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/locations`,
    data,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
  });
}

function getLocations(organizationId: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/locations/organization`,
    params: {
      organization: organizationId,
    },
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
    },
  });
}

function createKYC(data: { agent_name: string; agent_nik: string }) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_AUTH_URL2}/satu-sehat/e-kyc/generate-url`,
    headers: {
      "Clinic-ID": getSelectedIdClinic(),
      // "X-API-KEY": getSelectedIdClinic(),
      "Content-Type": "application/json",
    },
    data,
  });
}

const exported = {
  getPatientDetailsByNIK,
  getPatientDetailsByNIKMother,
  getPatientDetailsByIdentity,

  getPractionerByNIK,

  createKYC,
  createLocation,
  getLocations,
};

export default exported;

import { LockSimple, LockSimpleOpen } from "@phosphor-icons/react";
import useLocalStorage from "lib/helpers/useLocalstorage";

// type Props = {}

function ContextMenuLockMainMenu() {
  const [lockMainMenu, lockMainMenuSet] = useLocalStorage<boolean>(
    "lockMainMenu",
    false
  );

  return (
    <div className="flex flex-col p-1">
      <ul className="flex flex-col gap-y-1">
        <li
          className={[
            "flex gap-x-3 items-center  px-3 py-1 ",
            lockMainMenu
              ? "bg-gray-200 dark:bg-gray-700 dark:text-gray-200 cursor-default select-none"
              : "cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800 dark:text-gray-200",
          ].join(" ")}
          onClick={() => {
            lockMainMenuSet(true);
          }}
        >
          <LockSimple size={22} />
          Kunci menu utama
        </li>
        <li
          className={[
            "flex gap-x-3 items-center  px-3 py-1 ",
            lockMainMenu
              ? "cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-800 dark:text-gray-200"
              : "bg-gray-200 dark:bg-gray-700 dark:text-gray-200 cursor-default select-none",
          ].join(" ")}
          onClick={() => {
            lockMainMenuSet(false);
          }}
        >
          <LockSimpleOpen size={22} />
          Lepas menu utama
        </li>
      </ul>
    </div>
  );
}

export default ContextMenuLockMainMenu;

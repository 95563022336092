import { createSlice } from "@reduxjs/toolkit";

// export type Services = {
//   _id: string;
//   name: string;
//   logo: string;
//   [key: string]: string;
// };

export type TTypesPos = "salesService" | "salesPOS";
export type TStatusPos = "completed" | "partial" | "refunded";

export type TPayments = {
  method: string;
  metaData: any;
  cash: number;
  [key: string]: any;
};

export type TPosResponse = {
  _id: string;
  idService: string;
  createdAt: Date;
  status: TStatusPos;
  user: any;
  type: TTypesPos;
  metaData: any;
  grandTotal: number;
};

export interface IPosState {
  fee: number;
  subTotal: number;
  discount: number;
  globalDiscount: number;
  deposit: number;
  downPayment: number;
  tax: number;
  roundup: number;

  // grandTotal: number,
  // biayaAdministrasiTransaksi: number,
  categories: {
    _id: string;
    items: {
      [key: string]: any;
      name: string;
      qty: number;
      actualSellingPrice?: number;
      biayaRacik?: number;
    }[];
  }[];
  type: TTypesPos;
  metaData: {
    [key: string]: any;
  };
  payments: TPayments[];
  isActivePackagePrice: boolean;
  advantageBenefit: number;

  signature?: string;
}

const initialState: IPosState = {
  fee: 0,
  subTotal: 0,
  discount: 0,
  globalDiscount: 0,
  roundup: 0,
  deposit: 0,
  downPayment: 0,
  tax: 0,
  categories: [],
  payments: [],
  type: "salesPOS",
  isActivePackagePrice: false,
  advantageBenefit: 0,
  metaData: {},
};

export const clinicsSlice = createSlice({
  name: "pos",
  initialState: { ...initialState },
  reducers: {
    changeType: (
      state,
      action: {
        payload: TTypesPos;
      }
    ) => {
      state.type = action.payload;
    },
    initCategories: (state, action) => {
      state.categories = action.payload.map((category: any) => ({
        ...category,
        items: category.items.map((item: any) => ({
          ...item,
          actualSellingPrice: item.actualSellingPrice || item.price1 || 0,
        })),
      }));
    },
    initMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    deleteItem: (state, action) => {
      state.categories[action.payload.categoryIndex].items.splice(
        action.payload.itemIndex,
        1
      );
    },
    updateItems: (
      state,
      action: {
        payload: {
          values: { [key: string]: any };
          categoryIndex: number;
          itemIndex: number;
        };
      }
    ) => {
      for (const key in action.payload.values) {
        if (Object.prototype.hasOwnProperty.call(action.payload.values, key)) {
          const value = action.payload.values[key];
          state.categories[action.payload.categoryIndex].items[
            action.payload.itemIndex
          ][key] = value;
        }
      }
    },
    addItem: (
      state,
      action: {
        payload: { [key: string]: any };
      }
    ) => {
      const indexCategory = state.categories.findIndex(
        (item) => item._id === action.payload.type
      );

      const newItem = {
        ...action.payload,
        name: action.payload.name,
        qty: 1,
        // actualSellingPrice: action.payload.price1,
      };

      if (indexCategory >= 0) {
        const indexItem = state.categories[indexCategory].items.findIndex(
          (item) => item._id === action.payload._id
        );

        if (indexItem >= 0) {
          state.categories[indexCategory].items[indexItem].qty++;
        } else {
          state.categories[indexCategory].items.push(newItem);
        }
      } else {
        state.categories.push({
          _id: action.payload.type,
          items: [newItem],
        });
      }
    },
    updateDiscountPerItem: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.discount = Number(action.payload);
    },
    updateGlobalDiscount: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.globalDiscount = Number(action.payload);
    },
    updateGlobalRoundUp: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.roundup = Number(action.payload);
    },
    updateDownPayment: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.downPayment = Number(action.payload);
    },
    updateDeposit: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.deposit = Number(action.payload);
    },
    updateTax: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.tax = Number(action.payload);
    },
    addPaymentMethod: (
      state,
      action: {
        payload: number;
      }
    ) => {
      state.payments.push({
        method: "",
        metaData: {},
        cash: action?.payload || 0,
      });
    },
    updatePaymentMethod: (
      state,
      action: {
        payload: {
          index: number;
          key: string;
          value: any;
        };
      }
    ) => {
      state.payments[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    deletePaymentMethod: (
      state,
      action: {
        payload: {
          index: number;
        };
      }
    ) => {
      state.payments.splice(action.payload.index, 1);
    },
    takeSignature: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.signature = action.payload;
    },
    resetPos: (state) => {
      state.fee = 0;
      state.deposit = 0;
      state.subTotal = 0;
      state.discount = 0;
      state.downPayment = 0;
      state.tax = 0;
      state.categories = [];
      state.payments = [];
      state.type = "salesPOS";
      state.isActivePackagePrice = false;
      state.advantageBenefit = 0;
      state.metaData = {};
      state.signature = "";
    },
  },
});

export const {
  changeType,
  initCategories,
  initMetaData,
  deleteItem,
  updateItems,
  addItem,
  updateDiscountPerItem,
  resetPos,
  updateDownPayment,
  updateTax,
  addPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  updateDeposit,
  updateGlobalDiscount,
  updateGlobalRoundUp,
  takeSignature,
} = clinicsSlice.actions;

export default clinicsSlice.reducer;

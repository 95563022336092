import axios from "lib/fetch/axios";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";
import { format } from "date-fns";

export type TFilterPendaftaran = {
  tglDaftar: Date;
  page: number;
  limit: number;
};

export type TDetailKunjungan = {
  noUrut: string;
  tglDaftar: string;
  noKartu: string;
};

function getKesadaran() {
  //health check pcare
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kesadaran`,
  });
}

function getPatientDetailsByBpjs(code: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/peserta/${code}`,
    params: {
      code,
    },
  });
}

function getAllPendaftaran(params: TFilterPendaftaran) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/pendaftaran`,
    params: {
      ...params,
      tglDaftar: format(new Date(params.tglDaftar), "dd-MM-yyyy"),
    },
  });
}

function getDetailKunjungan(params: TDetailKunjungan) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kunjungan`,
    params,
  });
}

function getPcareCpptByNoka(nomorKartu: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kunjungan-by-noka/${nomorKartu}`,
  });
}

function getSarana() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/spesialis/sarana`,
  });
}

function getSpesialis() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/spesialis`,
  });
}

function getSubSpesialis(kdSpesialis: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/spesialis/${kdSpesialis}/subspesialis`,
  });
}

function getReferensiKhusus() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/spesialis/khusus`,
  });
}

function getAvailableProvider(
  type: string,
  params: {
    kdKhusus?: string;
    kdSarana?: string;
    kdSubSpesialis?: string;
    noKartu?: string;
    tglEstRujuk?: string;
  }
) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/spesialis/rujuk/${type}`,
    params,
  });
}

function createPendaftaran(
  idService: string,
  data: {
    kdProviderPeserta: string;
    tglDaftar: string;
    noKartu: string;
    kdPoli: string;
    keluhan: string;
    kunjSakit: string;
    sistole: string;
    diastole: string;
    beratBadan: string;
    tinggiBadan: string;
    respRate: string;
    lingkarPerut: string;
    heartRate: string;
    rujukBalik: string;
    kdTkp: string;
  }
) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/pendaftaran/${idService}`,
    data,
  });
}

function createKunjungan(idService: string) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kunjungan/${idService}`,
    data: {
      idService,
    },
  });
}

function downloadPelayanan(data: {
  noUrut: string;
  tglDaftar: string;
  noKartu: string;
}) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/pendaftaran`,
    data,
  });
}

function downloadKunjungan(data: {
  noUrut: string;
  tglDaftar: string;
  noKartu: string;
}) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kunjungan`,
    data,
  });
}

function trashKunjungan(params: {
  noUrut: string;
  tglDaftar: string;
  noKartu: string;
}) {
  return axios({
    method: "delete",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/kunjungan/${params.noKartu}`,
    params,
  });
}

function trashPendaftaran(params: {
  noUrut: string;
  tglDaftar: string;
  noKartu: string;
  kdPoli: string;
}) {
  return axios({
    method: "delete",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/pendaftaran/${
      params.noUrut
    }`,
    params,
  });
}

function getPolies(params = { page: "0", limit: "100" }) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/poli/fktp`,
    params,
  });
}

function getNakes(params = { page: "0", limit: "100" }) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/dokter`,
    params,
  });
}

function getPoliesAntreanOnline() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/antrean-online/poli`,
  });
}

export type TDoctorBpjsAntreanOnline = {
  namadokter: string;
  kodedokter: string;
  jampraktek: string;
  kapasitas: number;
};

function getDoctorAntreanOnline(params: { kodepoli: string }) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/antrean-online/doctor`,
    params,
  });
}

function getAlergi(type: "01" | "02" | "03") {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/bpjs/alergi`,
    params: {
      type,
    },
  });
}

function antreanOnlineHadir(data: {
  isBpjsAntreanOnline: boolean;
  idService: string;
  tanggalperiksa: string;
  kodepoli: string;
  nomorkartu: string;
}) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/antrean-online/panggil`,
    data,
  });
}

const exported = {
  getPatientDetailsByBpjs,
  getAllPendaftaran,
  getDetailKunjungan,
  getSarana,
  getSpesialis,
  getSubSpesialis,
  getReferensiKhusus,
  getAvailableProvider,
  createPendaftaran,
  createKunjungan,
  downloadPelayanan,
  downloadKunjungan,
  getPolies,
  trashKunjungan,
  trashPendaftaran,
  getPcareCpptByNoka,
  getNakes,
  getKesadaran,
  getAlergi,

  getPoliesAntreanOnline,
  getDoctorAntreanOnline,
  antreanOnlineHadir,
};

export default exported;

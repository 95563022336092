function LoadingOutlet() {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex items-center justify-between gap-x-4 px-4 py-3 shadow-sm">
        <div className="flex flex-col gap-x-4 gap-y-2">
          <span className="inline-flex h-5 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-3 w-24 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-3 w-48 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
        </div>
        <span className="ml-auto"></span>
        <span className="inline-flex h-4 w-44 rounded bg-gray-200 dark:bg-gray-700"></span>
        <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
      </div>

      <div className="flex items-center justify-between gap-x-4 px-4 py-3">
        <div className="flex flex-col gap-x-4 gap-y-2">
          <div className="flex gap-x-4">
            <span className="inline-flex h-3 w-7 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-3 w-16 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
          <span className="inline-flex h-5 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>

        <div className="flex flex-col gap-x-4 gap-y-2">
          <div className="flex gap-x-4">
            <span className="inline-flex h-3 w-7 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-3 w-16 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
          <span className="inline-flex h-5 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>

        <span className="ml-auto inline-flex h-5 w-44 rounded bg-gray-200 dark:bg-gray-700"></span>
      </div>

      <hr />

      <div className="mt-2 px-4">
        <span className="inline-flex h-60 w-full animate-pulse rounded bg-gray-200 dark:bg-gray-700"></span>
      </div>
    </div>
  );
}

export default LoadingOutlet;

import axios from "lib/fetch/axios";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";
import { AvailablePaymentMethod } from "../paymentMethods";
import { TProduct } from "../productTypes";

export type TDeposit = {
  hasDeposit: boolean;
  amount: number;
  paymentMethod: AvailablePaymentMethod;
  paymentProofImage?: string;
};

export type TClaim = {
  isActive: boolean;
  data: TProduct[];
};

export type TPayloadCreate = {
  patient: any;
  dateStart: Date;
  type: "rekam-medis" | "laboratorium";
  statusBooking?: "pending" | "in-progress" | "cancelled" | "done";
  channel: "booking-system" | "planning" | "manual";
  idRecipient?: string;
  description?: string;
  operator?: any;
  metaData?: {
    deposit?: TDeposit;
    claim?: TClaim;
  };
};

export type TPayloadQueue = {
  idIks?: string;
  idPoliHospital?: string;
  idPatient?: string;
  servedBy?: string;
  isPriority?: boolean;
};

function getStates() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking/states`,
  });
}

function getCalendars(params: { dateStart: string; dateEnd: string }) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking`,
    params,
  });
}

function create(data: TPayloadCreate) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking`,
    data,
  });
}

function queue(id: string, data: TPayloadQueue) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking/${id}/queue`,
    data,
  });
}

function update(id: string, data: TPayloadQueue) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking/${id}`,
    data,
  });
}

function cancel(id: string) {
  return axios({
    method: "delete",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking/${id}`,
  });
}

function trash(id: string) {
  return axios({
    method: "delete",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/booking/${id}/trash`,
  });
}

const exported = {
  getStates,
  getCalendars,
  create,
  queue,
  update,
  cancel,
  trash,
};

export default exported;

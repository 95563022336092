import ProtectedClinicRoute from "components/atoms/ProtectedClinicRoute";

import Header from "./Header";
import Sidebar from "./Sidebar";

import { Outlet } from "react-router-dom";

function ClinicsOutlet() {
  return (
    <ProtectedClinicRoute>
      <>
        <Header />
        <div className="relative flex flex-col lg:flex-row">
          <Sidebar />

          <Outlet />
        </div>
      </>
    </ProtectedClinicRoute>
  );
}

export default ClinicsOutlet;

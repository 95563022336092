import { ArrowSquareOut } from "@phosphor-icons/react";
import { useAppSelector } from "lib/store/hooks";
import ContextMenuKiosk from "./ContextMenuKiosk";
import modal from "react-components/dist/components/Modal";
import Button from "react-components/dist/components/Button";
import { useCallback, useState } from "react";
import APIClinics from "lib/constants/api/clinics";

function Kiosk() {
  const users = useAppSelector((state) => state.users);

  const [isLoadingKiosk, isLoadingKioskSet] = useState(false);

  const getKioskPage = useCallback(async () => {
    try {
      isLoadingKioskSet(true);
      const response = await APIClinics.getKioskPage();

      isLoadingKioskSet(false);

      window.open(response.data.link, "kiosk-mejadokter");
    } catch (error) {
      console.log(error);
    }
  }, []);

  function onContextMenuKiosk(e: any) {
    e.preventDefault();

    modal.show(<ContextMenuKiosk onClose={modal.hide} />, {
      close: {
        outsideClick: true,
        closeOnEscape: true,
        display: false,
      },
      container: {
        className: "",
      },
      overlay: {
        timeout: 0,
        // className: "absolute top-0 left-0",
        // overrideClassName: "bg-black/0",
        animatedClassNames: {
          appear: "opacity-0 bg-black/0",
          appearActive: "opacity-0 bg-black/0",
          appearDone: "opacity-100 bg-black/0",
          enter: "opacity-0 bg-black/0",
          enterActive: "opacity-0 bg-black/0",
          enterDone: "opacity-100 bg-black/0",
          exit: "",
          exitActive: "opacity-0 bg-black/0",
          exitDone: "opacity-0 bg-black/0",
        },
      },
      wrapper: {
        className:
          "bg-white dark:bg-gray-900 w-[220px] relative bg-white shadow-lg rounded",
        timeout: 0,
        style: {
          left: e.clientX,
          top: e.clientY,
        },
      },
    });
  }

  if (
    users.profile?.entity === "owner" ||
    !!users.profile?.authority?.settings?.["kiosk"]
  )
    return (
      <div className="group relative mt-1">
        <Button
          isLoading={isLoadingKiosk}
          ripple
          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900 "
          state="custom"
        >
          <button onClick={getKioskPage} onContextMenu={onContextMenuKiosk}>
            <ArrowSquareOut size={22} />
          </button>
        </Button>
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Buka jendela KIOSK
        </span>
      </div>
    );

  return null;
}

export default Kiosk;

import Speech from "speak-tts";

import { useAppDispatch } from "lib/store/hooks";
import { useEffect } from "react";
import { setSpeechUtterance } from "lib/store/slices/components";

function useSpeech() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const speech = new Speech();
    speech
      .init({
        volume: 0.75,
        lang: "id-ID",
        rate: 0.9,
        pitch: 1.1,
        voice: "Google Bahasa Indonesia",
        //'splitSentences': false,
        listeners: {
          onvoiceschanged: (voices: any) => {
            // console.log("Voices changed", voices);
          },
        },
      })
      .then((data: any) => {
        // console.log("Speech is ready", data);
        // _addVoicesList(data.voices);
        // _prepareSpeakButton(speech);
      })
      .catch((e: Error) => {
        console.error("An error occured while initializing : ", e);
      });

    dispatch(setSpeechUtterance(speech));
  }, [dispatch]);

  return null;
}

export default useSpeech;

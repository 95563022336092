import { configureStore } from "@reduxjs/toolkit";

import clinicReducer from "./slices/clinics";
import componentReducer from "./slices/components";
import userReducer from "./slices/users";
import servicesReducer from "./slices/services";
import calendarsReducer from "./slices/calendars";
import posReducer from "./slices/pos";
import notificationsReducer from "./slices/notifications";
import tutorialsReducer from "./slices/tutorials";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

const reducers = combineReducers({
  clinics: clinicReducer,
  components: componentReducer,
  users: userReducer,
  services: servicesReducer,
  calendars: calendarsReducer,
  pos: posReducer,
  notifications: notificationsReducer,
  tutorials: tutorialsReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["clinics", "components", "users"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// type Props = {}
// {}: Props

export function getQueryParams(search: string) {
  search = decodeURI(search);
  const queries = search.split("&");
  return queries
    .filter((item: string) => {
      return item !== "";
    })
    .reduce((acc: { [key: string]: any }, item: string) => {
      const [key, value] = item.split("=");
      if (key.includes("[]") || key.includes("%5B%5D")) {
        if (!acc[key]) acc[key] = [];
        acc[key].push(value);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
}

function useQueryParams() {
  let { search } = useLocation();

  if (search.includes("?")) search = search.substring(1);

  const q = useMemo(() => {
    return getQueryParams(search);
  }, [search]);

  return q;
}

export default useQueryParams;

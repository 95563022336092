import axios from "lib/fetch/axios";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";

export const CLINIC_PELAYANAN_ANTRIAN_BARU = "CLINIC_PELAYANAN_ANTRIAN_BARU";
export const CLINIC_PELAYANAN_DIPERIKSA = "CLINIC_PELAYANAN_DIPERIKSA";
export const CLINIC_KASIR_BAYAR = "CLINIC_KASIR_BAYAR";
export const CLINIC_KIOSK_PEMANGGIL = "CLINIC_KIOSK_PEMANGGIL";

function getNotifications(params: { limit: number; page: number }) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/notifications`,
    params,
  });
}

function createNotification(data: {
  referenceType: "kiosk" | "clinic" | "user";
  type: string;
  title: string;
  message: string;
  metadata: any;
}) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/notifications`,
    data,
  });
}

function readNotification(idNotification: string) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/notifications/${idNotification}/is-read-true`,
  });
}

const exported = {
  getNotifications,
  createNotification,
  readNotification,
};

export default exported;

import axios from "lib/fetch/axios";
import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";

export type TServiceStatus =
  | "being-served"
  | "queuing"
  | "already-served"
  | "checkout"
  | "in-progress";

export type TUsersEntity = "owner" | "nurse" | "staff" | "doctor" | "bidan";

export function mapEntity(data: { entity: TUsersEntity; [key: string]: any }) {
  switch (data.entity) {
    case "doctor":
      if (data?.doctor?.isBidan) return "Bidan";
      return "Dokter";

    case "nurse":
      return "Nurse";

    case "staff":
    default:
      return "Staff";
  }
}

export type TFilterList = {
  page: string;
  limit: string;
  entity?: TUsersEntity;
  term?: string;
  isBidan?: Boolean;
};

function list(params: TFilterList) {
  params.isBidan = undefined;
  if (params.entity === "bidan") {
    params.entity = "doctor";
    params.isBidan = true;
  } else if (params.entity === "doctor") {
    params.entity = "doctor";
  }
  return axios({
    method: "get",
    params,
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees`,
  });
}

function getDetailsByEntity(entity: string) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/${entity}/details`,
  });
}

function getDetailsByIdUser(id: string) {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees/${id}`,
  });
}

function updatePassword(data: { password: string }) {
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_AUTH_URL}/api/v1/users/password`,
    data,
  });
}

function changeRoles(id: string, data: any) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees/${id}/change-roles`,
    data,
  });
}

function updateProfile(
  entity: string,
  data: {
    fullName: string;
    ijinPraktek: string;
    metaData?: {
      signature?: string;
      [key: string]: any;
    };
  }
) {
  switch (entity) {
    case "doctor":
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/doctors/details`,
        data,
      });
    case "owner":
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/owners/details`,
        data,
      });
    case "staff":
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/staffs/details`,
        data,
      });
    case "nurse":
      return axios({
        method: "put",
        url: `${process.env.REACT_APP_AUTH_URL}/api/v1/nurses/details`,
        data,
      });

    default:
      break;
  }
}

function updateUser(id: string, data: any) {
  return axios({
    method: "put",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees/${id}`,
    data,
  });
}

function createUser(data: any) {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees`,
    data,
  });
}

function removeAccess(id: string) {
  return axios({
    method: "delete",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/employees/${id}`,
  });
}

const exported = {
  list,
  getDetailsByEntity,
  getDetailsByIdUser,
  updatePassword,
  updateProfile,
  removeAccess,
  createUser,
  updateUser,
  changeRoles,
};

export default exported;
